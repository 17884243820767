import request from '@/core/services/request'

//  获取签名列表
export function mailSignList(data = {}) {
  return request({
    url: '/system/mailSignature/listMailSignature',
    method: 'post',
    data,
  })
}
//  保存签名
export function mailSignSave(data = {}) {
  return request({
    url: '/system/mailSignature/save',
    method: 'post',
    data,
  })
}
//  删除签名
export function mailSignDelete(data = {}) {
  return request({
    url: '/system/mailSignature/delete',
    method: 'post',
    data,
  })
}
//  获取规则列表
export function mailRulesList(data = {}) {
  return request({
    url: '/system/mailReceivingRules/listMailReceivingRules',
    method: 'post',
    data,
  })
}
//  保存规则
export function mailRulesSave(data = {}) {
  return request({
    url: '/system/mailReceivingRules/save',
    method: 'post',
    data,
  })
}
//  删除规则
export function mailRulesDelete(data = {}) {
  return request({
    url: '/system/mailReceivingRules/delete',
    method: 'post',
    data,
  })
}
//  禁用启用规则
export function mailRulesTrigger(data = {}) {
  return request({
    url: '/system/mailReceivingRules/enable',
    method: 'post',
    data,
  })
}
//  获取模板列表
export function mailTemplateList(data = {}) {
  return request({
    url: '/system/mailTemplate/listMailTemplate',
    method: 'post',
    data,
  })
}
//  保存模板
export function mailTemplateSave(data = {}) {
  return request({
    url: '/system/mailTemplate/save',
    method: 'post',
    data,
  })
}
//  删除模板
export function mailTemplateDelete(data = {}) {
  return request({
    url: '/system/mailTemplate/delete',
    method: 'post',
    data,
  })
}
//  获取其他设置
export function getMailOtherConfig(data = {}) {
  return request({
    url: '/system/mailOtherConfig/listMailOtherConfig',
    method: 'post',
    data,
  })
}
//  保存其他设置
export function mailOtherConfigSave(data = {}) {
  return request({
    url: '/system/mailOtherConfig/save',
    method: 'post',
    data,
  })
}
//  删除其他设置
export function mailOtherConfigDelete(data = {}) {
  return request({
    url: '/system/mailOtherConfig/delete',
    method: 'post',
    data,
  })
}
