<template>
  <div>
    <script :id="id" type="textain"></script>
  </div>
</template>

<script>
  import '../../../public/static/UEditor/ueditor.config.js'
  import '../../../public/static/UEditor/ueditor.all.js'
  import '../../../public/static/UEditor/ckeditor.min.office.js'
  import '../../../public/static/UEditor/lang/en/en.js'
  import '../../../public/static/UEditor/lang/zh-cn/zh-cn.js'
  import '../../../public/static/UEditor/themes/default/css/ueditor.min.css'
  import '../../../public/static/UEditor/themes/iframe.css'
  import { uploadFile } from '@/api/mail/mail.js'

  let lock = false

  export default {
    name: 'UEditor',
    props: {
      id: {
        type: String,
        default: 'editor',
      },
      frameHeight: {
        type: [String, Number],
        default: '500',
      },
      selfInit: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        editor: null,
        lang: 'en',

        config: {
          //可以在此处定义工具栏的内容
          autoHeightEnabled: false,
          elementPathEnabled: false,
          zIndex: 2000,
          wordCount: false,
          autoFloatEnabled: true,
          allowDivTransToP: false,
          initialContent: '', //初始化编辑器的内容,也可以通过textarea/script给值，看官网例子 请输入内容
          autoClearinitialContent: true, //是否自动清除编辑器初始内容，注意：如果focus属性设置为true,这个也为真，那么编辑器一上来就会触发导致初始化的内容看不到了
          initialFrameWidth: null,
          initialFrameHeight: '500',
          BaseUrl: '',
          UEDITOR_HOME_URL: 'static/UEditor/',
          toolbars: [
            [
              'undo', //撤销
              'redo', //重做
              'forecolor', //字体颜色
              'backcolor', //背景色
              'fontfamily', //字体
              'fontsize', //字号
              '|',
              'bold', //加粗
              'italic', //斜体
              'underline', //下划线
              'strikethrough', //删除线
              '|',
              'formatmatch', //格式刷
              'removeformat', //清除格式
              '|',
              'insertorderedlist', //有序列表
              'insertunorderedlist', //无序列表
              '|',
              'inserttable', //插入表格
              'paragraph', //段落格式
              // 'simpleupload', //单图上传
              'imageleft', //居中
              'imagecenter', //居中
              'imageright', //居中
              // 'attachment', //附件
              '|',
              'justifyleft', //居左对齐
              'justifycenter', //居中对齐
              'justifyright', //居中对齐
              'horizontal', //分隔线
              '|',
              // 'blockquote', //引用
              // 'insertcode', //代码语言
              // 'source', //源代码
              // 'preview', //预览
              'uploadimg',
              'link', // 链接
              'fullscreen', //全屏
            ],
          ],
        },
      }
    },
    mounted() {
      if (this.selfInit) {
        this.initUE()
      }

      this.getLang()
    },
    destoryed() {
      this.editor.destory()
    },
    methods: {
      getLang() {
        let language = localStorage.getItem('language')

        if (JSON.parse(language)) {
          this.lang = JSON.parse(language).language
        }
      },

      initUE(height) {
        let that = this

        UE.registerUI('uploadimg', function (editor, uiName) {
          let me = this
          let btn = new UE.ui.Button({
            name: 'uploadimg',
            title: that.lang === 'en' ? 'Upload Image' : '上传图片',
            cssRules: 'background-position: -380px 0;',
            onclick: function () {
              const inp = document.createElement('input')
              inp.type = 'file'
              inp.accept = 'image/*'
              inp.click()
              inp.addEventListener('change', function () {
                const file = this.files
                uploadFile({
                  file: file[0],
                  flagSaveName: 1,
                  source: 1,
                  uploadType: 1,
                }).then((res) => {
                  me.execCommand(
                    'inserthtml',
                    `<img src="https://weiwo-erp.oss-accelerate.aliyuncs.com/${res.data.path}" />`
                  )
                })
              })
            },
          })

          editor.on('selectionchange', function () {
            var state = this.queryCommandState(uiName)
            if (state == -1) {
              btn.setDisabled(true)
            } else {
              btn.setDisabled(false)
            }
          })
          // editor.addListener(
          //   'beforefullscreenchange',
          //   function (event, isFullScreen) {
          //     // alert(isFullScreen ? '全屏' : '默认')
          //   }
          // )
          return btn
        })
        //初始化UE
        const _this = this
        this.editor = UE.delEditor(this.id)
        this.config.initialFrameHeight = height || this.frameHeight
        this.editor = UE.getEditor(this.id, this.config)
        // let ueEl = document.querySelector('#editor')
        // ueEl.setAttribute('style', 'height: calc(100% - 10px) !important')
        this.editor.addListener('ready', () => {
          // 修改了ueditor.all.js的autoupload 26665行，拖拽和复制文件进入触发
          _this.editor.addListener('chooseFile', (name, files) => {
            _this.$emit('fileIn', files)
          })
        })
        this.editor.addListener('focus', () => {
          that.$emit('ueditor-focus')
        })
      },
      // 设置编辑器高度
      setUEditorHeight(height) {
        let editor = document.querySelector('#edui1_iframeholder')
        if (editor) {
          editor.style.height = `${height || this.frameHeight}px`
        }
      },
      getUEContent: function () {
        return this.editor.getContent()
      },
      getContentTxt: function () {
        return this.editor.getContentTxt()
      },
      setReadyContent(text) {
        this.editor.setContent(text)
      },
      // 设置当前行的字体样式
      setFocusText(...args) {
        this.editor.execCommand(...args)
      },
      setContent(text) {
        this.editor.addListener('ready', () => {
          lock = true
          this.editor.setContent(text)
        })
      },
      render() {
        this.editor.render()
      },
      //向富文本最后追加标签
      inSertAfter() {
        let ue = UE.getEditor(this.id)
        ue && ue.focus()
        // ue.execCommand('inserthtml', 'code html') // 向光标处插入
        ue && ue.setContent('some html code here<br/>asdasdasdasdasdasdasd', true) // 向内最后插入内容
      },
      getReady() {
        var ue = UE.getEditor(this.id)
        ue && ue.ready(() => { })
      },
    },
  }
</script>

<style lang="scss" scoped>
  #edui1 {
    height: 100%;
  }
</style>
